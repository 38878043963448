import { NgTemplateOutlet, NgClass } from '@angular/common';
import { Component, Input, Signal } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { WithRouterLinkPipe } from '@studiohyperdrive/ngx-utils';

import { HasFeatureDirective } from '@cjm/shared/features';
import { VLoketAppRoutePaths } from '@cjm/shared/route-paths';
import { MediaQueryMin, MediaQueryMax } from '@cjm/shared/types';
import { VloketLink, FaIconComponent, LinkComponent } from '@cjm/shared/ui/common';
import { MediaQueryDirective } from '@cjm/shared/ui/device';
import { TranslateWithRouterLinksPipe } from '@cjm/v-loket/shared';

import { SidebarService } from '../../../data';
import { I18nKeys } from '../../../i18n';
import { SidebarItemComponent } from '../sidebar-item/sidebar-item.component';
import { SidebarItemEntity } from '../sidebar-item/sidebar-item.types';

@Component({
	selector: 'cjm-sidebar',
	templateUrl: './sidebar.component.html',
	styleUrls: ['./sidebar.component.scss'],
	// Denis: in order to set the c-sidebar class dynamically based on the
	// isActive$ observable, we need to use the host property.
	// The same effect cannot be achieved through regular HostBinding()
	// eslint-disable-next-line @angular-eslint/no-host-metadata-property
	host: {
		'[class.c-sidebar]': 'isActive()',
		'[class.c-sidebar--collapsed]': 'isCollapsed()'
	},
	standalone: true,
	imports: [
		MediaQueryDirective,
		LinkComponent,
		FaIconComponent,
		NgTemplateOutlet,
		NgClass,
		HasFeatureDirective,
		SidebarItemComponent,
		TranslateWithRouterLinksPipe,
		TranslateModule,
		WithRouterLinkPipe
	]
})
export class SidebarComponent {
	/**
	 * asNav
	 *
	 * The asNav attribute allows the parent to define if
	 * the sidebar needs to be wrapped in a nav element.
	 *
	 * Default is set to true.
	 */
	@Input() public asNav: boolean = true;

	public showAllItems: boolean = false;
	public readonly i18nKeys: typeof I18nKeys = I18nKeys;
	public readonly mediaQueryMin: typeof MediaQueryMin = MediaQueryMin;
	public readonly mediaQueryMax: typeof MediaQueryMax = MediaQueryMax;

	public readonly isActive: Signal<boolean> = this.sidebarService.isActive;
	public readonly isCollapsed: Signal<boolean> = this.sidebarService.isCollapsed;
	public readonly sidebarTitle: Signal<string> = this.sidebarService.sidebarHeaderTitle;
	public readonly sidebarHeaderLink: Signal<VloketLink> = this.sidebarService.sidebarHeaderLink;
	public readonly sidebarItems: Signal<SidebarItemEntity[]> = this.sidebarService.sidebarItems;
	public readonly sidebarActiveFirst: Signal<SidebarItemEntity[]> = this.sidebarService.sidebarItemsActiveFirst;
	public readonly sidebarActionCount: Signal<Record<string, number>> = this.sidebarService.sidebarActionCount;

	constructor(private readonly sidebarService: SidebarService) {}

	/**
	 * collapseSidebar
	 *
	 * The collapseSidebar method will trigger the setSidebarCollapsed method on the
	 * SidebarService.
	 */
	public collapseSidebar(): void {
		this.sidebarService.setSidebarCollapsed(!this.isCollapsed());
	}

	/**
	 * toggleAllItems
	 *
	 * The toggleAllItems responds to the 'toggleAll' event on the sidebar-item.
	 * It will then toggle the current 'showAllItems' value.
	 */
	public toggleAllItems(): void {
		this.showAllItems = !this.showAllItems;
	}

	protected readonly vloketAppRoutes = VLoketAppRoutePaths;
}
