@if (item) {
	@if (item.route) {
		<a
			class="c-sidebar-item"
			[ngClass]="{ 'c-sidebar-item--active': item.active }"
			[routerLink]="item.route"
			[title]="item.text"
			[ariaCurrent]="item.active ? 'page' : null"
			(click)="toggleAllItems()"
		>
			<ng-container [ngTemplateOutlet]="itemContentTmpl" />
		</a>
	} @else {
		<div class="c-sidebar-item" [ngClass]="{ 'c-sidebar-item--active': item.active }">
			<ng-container [ngTemplateOutlet]="itemContentTmpl" />
		</div>
	}
}

<ng-template #itemContentTmpl>
	<fa-icon matBadgeColor="accent" [matBadge]="badgeCount" [iconType]="item.iconType" [icon]="item.icon" />

	<span aria-hidden="false" [ngClass]="{ 'c-sidebar-item--icon-only': iconOnly }">{{ item.text }}</span>
</ng-template>
