@if (isActive()) {
	<div class="c-sidebar__sidebar-content" [ngClass]="{ 'c-sidebar__sidebar-content--open-on-mobile': showAllItems }">
		<div *mediaQuery="mediaQueryMax.Desktop" class="c-sidebar__sidebar-content__header">
			@if (sidebarTitle() || sidebarHeaderLink()) {
				<div class="c-sidebar__sidebar-content__header__title">
					@if (sidebarTitle()) {
						<div class="c-vloket-header c-vloket-header--h5 u-no-margin-bottom">
							{{ sidebarTitle() }}
						</div>
					}
					@if (sidebarHeaderLink(); as link) {
						<cjm-link
							class="c-vloket-text--s c-cjm-link--no-decoration"
							[title]="link.title"
							[to]="link.url"
							[type]="link.type"
							[disabled]="link.disabled"
							[tooltip]="link.tooltip"
							>{{ link.text }}</cjm-link
						>
					}
				</div>
			}
		</div>
		<div *mediaQuery="mediaQueryMin.Desktop" class="c-sidebar__sidebar-content__header">
			@if (isCollapsed() === false && (sidebarTitle() || sidebarHeaderLink())) {
				<div class="c-sidebar__sidebar-content__header__title">
					@if (sidebarTitle()) {
						<div class="c-vloket-header c-vloket-header--h5 u-no-margin-bottom">
							{{ sidebarTitle() }}
						</div>
					}
					@if (sidebarHeaderLink(); as link) {
						<cjm-link
							class="c-vloket-text--s c-cjm-link--no-decoration"
							[title]="link.title"
							[to]="link.url"
							[type]="link.type"
							[disabled]="link.disabled"
							[tooltip]="link.tooltip"
							>{{ link.text }}</cjm-link
						>
					}
				</div>
			}
			<button
				class="c-sidebar__close-button"
				[title]="
					isCollapsed()
						? (i18nKeys.Sidebar.Actions.Open | translate)
						: (i18nKeys.Sidebar.Actions.Close | translate)
				"
				[ariaLabel]="
					isCollapsed()
						? (i18nKeys.Sidebar.Actions.Open | translate)
						: (i18nKeys.Sidebar.Actions.Close | translate)
				"
				(click)="collapseSidebar()"
			>
				<fa-icon icon="fa-chevron-left" />
			</button>
		</div>
		<button
			*mediaQuery="mediaQueryMax.Desktop"
			class="c-sidebar__close-button"
			[title]="
				isCollapsed()
					? (i18nKeys.Sidebar.Actions.Open | translate)
					: (i18nKeys.Sidebar.Actions.Close | translate)
			"
			[ariaLabel]="
				isCollapsed()
					? (i18nKeys.Sidebar.Actions.Open | translate)
					: (i18nKeys.Sidebar.Actions.Close | translate)
			"
			(click)="toggleAllItems()"
		>
			<fa-icon icon="fa-chevron-down" />
		</button>
		@if (asNav) {
			<nav class="c-sidebar__sidebar-content__items">
				<ng-container [ngTemplateOutlet]="sidebarContentTmpl" />
			</nav>
		} @else {
			<ul class="c-sidebar__sidebar-content__items">
				<ng-container *mediaQuery="mediaQueryMax.Desktop">
					@for (item of sidebarActiveFirst(); track item.id; let i = $index) {
						<li *hasFeature="item.featureFlag">
							@if (showAllItems || i === 0) {
								<cjm-sidebar-item
									[item]="item"
									[badgeCount]="sidebarActionCount()[item.id]"
									(toggleAll)="toggleAllItems()"
								/>
							}
						</li>
					}
				</ng-container>
				<ng-container *mediaQuery="mediaQueryMin.Desktop">
					@for (item of sidebarItems(); track item.id) {
						<li *hasFeature="item.featureFlag">
							<cjm-sidebar-item
								[item]="item"
								[badgeCount]="sidebarActionCount()[item.id]"
								[iconOnly]="isCollapsed()"
							/>
						</li>
					}
				</ng-container>
			</ul>
		}
	</div>
	@if (!showAllItems) {
		<div class="c-sidebar__page-content">
			<ng-container [ngTemplateOutlet]="pageContent" />
		</div>
	}
} @else {
	<ng-container [ngTemplateOutlet]="pageContent" />
}

<ng-template #sidebarContentTmpl>
	<ul class="c-sidebar__sidebar-content__items">
		<ng-container *mediaQuery="mediaQueryMax.Desktop">
			@for (item of sidebarActiveFirst(); track item.id; let i = $index) {
				<li *hasFeature="item.featureFlag">
					@if (showAllItems || i === 0) {
						<cjm-sidebar-item
							[item]="item"
							[badgeCount]="sidebarActionCount()[item.id]"
							(toggleAll)="i === 0 ? null : toggleAllItems()"
						/>
					}
				</li>
			}
		</ng-container>

		<ng-container *mediaQuery="mediaQueryMin.Desktop">
			@for (item of sidebarItems(); track item.id) {
				<li *hasFeature="item.featureFlag">
					<cjm-sidebar-item
						[item]="item"
						[badgeCount]="sidebarActionCount()[item.id]"
						[iconOnly]="isCollapsed()"
					/>
				</li>
			}
		</ng-container>
	</ul>
</ng-template>

<ng-template #pageContent>
	<ng-content />
</ng-template>
